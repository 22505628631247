<template>
    <div class="edit-delivery">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.deliveryUpdate')"
            :title="$t('messages.deliveryUpdate')"
            @submitPressed="submitPressed"
        />
        <b-card no-body>
            <b-tabs card content-class="mt-3">
                <b-tab :title="$t('forms.info')" active>
                    <delivery-form
                        v-if="delivery"
                        :deliveryObject="delivery"
                        :action="action"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab :title="$t('forms.files')">
                    <files-form :resource="$DeliveryItems" :resource-id="$route.params.id" message="deliveryUpdated"
                                :file-tags="['files']"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import DeliveryForm from "./components/DeliveryForm.vue";
import FilesForm from "@/components/Files/FilesForm.vue";

export default {
    components: {
        TitleBar,
        DeliveryForm,
        FilesForm
    },
    data() {
        return {
            delivery: null,
            action: null
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$DeliveryItems.getResource({id}).then((response) => {
                this.delivery = response.data;
                this.delivery.eta = new Date(response.data.eta)
                this.delivery.expiryDate = new Date(response.data.expiryDate)
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
